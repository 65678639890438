<template>
  <div class="swiper-container my-swiper2" style="height: calc(100vh - 120px)">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="item in list"
        :key="item.DataID"
        @click="toDetail(item)"
      >
        <div class="img-container">
          <!-- <img class="img" :src="baseURL + item.ImgPath" :alt="item.Title"> -->
          <el-image :src="item.ImgPath" class="img">
            <div slot="error" class="image-error">
              <div class="image-error-contianer">
                <i class="el-icon-picture-outline image-error-icon"></i>
                <p class="image-error-text">加载失败</p>
              </div>
            </div>
          </el-image>
        </div>
        <p class="item-title">{{ item.Title }}</p>
      </div>
    </div>
    <!-- <div class="swiper-pagination"></div> -->
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  name: "index",
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    toDetail(data) {
      if (data.ReType === 1) {
        this.$router.push({
          path: "/collection/detail",
          query: {
            id: data.ReID,
          },
        });
      }
      if (data.ReType === 2) {
        this.https({
          url: "/api/webapi/Exhibition_GetDetail",
          method: "get",
          data: { ExhibitionID: data.ReID },
          options: {
            message: false,
            loading: false,
          },
        }).then(({ data }) => {
          if (data.StatusID === 2) {
            this.$router.push({
              path: "/exhibition/collection",
              query: {
                id: data.ReID,
              },
            });
          } else {
            this.$message({ message: "当前展览未开启！", type: "error" });
          }
        });
      }
      if (data.ReType === 3) {
        window.open(data.Link, "_blank");
      }
    },
    getBanner() {
      this.https({
        url: "/api/webapi/GetBanners",
        method: "get",
        data: {
          TypeID: 0,
        },
        options: {
          message: false,
          loading: true,
        },
      }).then(({ data }) => {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          if (element.ImgPath.slice(0, 4) !== "http") {
            element.ImgPath = this.baseURL + element.ImgPath;
          }
        }
        this.list = data;
        this.$nextTick(() => {
          if (this.list.length > 0) {
            new Swiper(".my-swiper2", {
              speed: 5000,
              freeMode: true,
              loop: true,
              watchSlidesProgress: true,
              autoplay: {
                // delay: 3000,
                // stopOnLastSlide: false,
                // disableOnInteraction: false
                delay: 0,
                stopOnLastSlide: false,
                disableOnInteraction: false,
              },
              slidesPerView: "auto",
              // spaceBetween: '50',
              slidesOffsetBefore: "100",
              // slidesPerView: 'auto', //css
              // pagination: {
              //   el: '.my-swiper2 .swiper-pagination'
              // }
              touchEnd: function() {
                this.update()
              }
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  display: block;
  &::v-deep .image-error {
    height: 100%;
    padding-top: 0;
  }
}
.swiper-wrapper {
  /* 这里是改变其动画效果为匀速形式，不加此样式的话会造成滚动卡顿，看起来效果不平滑 */
  /* 样式做了各种浏览器的兼容 */
  transition-timing-function: linear !important;
  -webkit-transition-timing-function: linear !important;
  -moz-transition-timing-function: linear !important;
  -ms-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  will-change: transform;
  filter: blur(0px)!important;
  z-index: 1!important;
  -webkit-backface-visibility: hidden!important;
  -webkit-transform-style: preserve-3d!important;
}
// .el-image img {
//   transform: perspective(1px)!important;
//   filter: blur(1px)!important;
//   -webkit-filter: blur(1px)!important;
//   z-index: 1!important;
//   -webkit-backface-visibility: hidden!important;
//   -webkit-transform-style: preserve-3d!important;
// }

</style>