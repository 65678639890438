<template>
    <div :class="['pubu',!haspd ? 'pd0': '']">
        <div class="list" :style="{width: width + 'rem'}" ref="left">
            <div class="block" v-for="(item,index) in left" :key="index" @click="toDetail(item)">
                <el-image :src="item.picture" class="item-img">
                    <div slot="error" class="image-error">
                        <div class="image-error-contianer">
                        <i class="el-icon-picture-outline image-error-icon"></i>
                        <p class="image-error-text">加载失败</p>
                        </div>
                    </div>
                </el-image>
                <!-- <img :src="item.picture" alt=""> -->
                <p class="block-title">{{ item.name }}</p>
                <div class="block-info">
                    <p>分类：{{item.categoryOne}}{{item.categoryTwo ? '-'+item.categoryTwo : ''}}{{item.categoryThree ? '-'+item.categoryThree : ''}}{{item.categoryFour ? '-'+item.categoryFour : ''}}</p>
                    <p>作者：{{ item.author }}</p>
                    <p>创作年代：{{ item.creationYear }}</p>
                </div>
            </div>
        </div>
        <div class="list" :style="{width: width + 'rem'}" ref="right" >
            <div class="block" v-for="(item,index) in right" :key="index" @click="toDetail(item)">
                <el-image :src="item.picture" class="item-img">
                    <div slot="error" class="image-error">
                        <div class="image-error-contianer">
                        <i class="el-icon-picture-outline image-error-icon"></i>
                        <p class="image-error-text">加载失败</p>
                        </div>
                    </div>
                </el-image>
                <!-- <img :src="item.picture" alt=""> -->
                <p class="block-title">{{ item.name }}</p>
                <div class="block-info">
                    <p>分类：{{item.categoryOne}}{{item.categoryTwo ? '-'+item.categoryTwo : ''}}{{item.categoryThree ? '-'+item.categoryThree : ''}}{{item.categoryFour ? '-'+item.categoryFour : ''}}</p>
                    <p>作者：{{ item.author }}</p>
                    <p>创作年代：{{ item.creationYear }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        haspd: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: '4.6'
        }
    },
    data() {
        return {
            datas: [],
            left: [],
            right: [],
            leftHeight: 0,
            rightHeight: 0,
            lock: false,
            lockCount: 0
        }
    },
    methods: {
        getStatus() {
            return this.lock
        },
        getSort(data) {
            this.lock = true
            data.forEach(item => {
                const that = this
                var img = new Image()
                img.src = item.picture
                img.onload = function() {
                    if(that.leftHeight <= that.rightHeight ) {
                        that.left.push(item)
                        that.leftHeight = that.leftHeight + Number(img.height)
                    }
                    else {
                        that.rightHeight = that.rightHeight + Number(img.height)
                        that.right.push(item)
                    }
                    that.lockCount += 1
                    if(that.lockCount === data.length) {
                        that.lockCount = 0
                        that.lock = false
                    }
                }
                img.onerror = function() {
                    if(that.leftHeight < that.rightHeight ) {
                        that.left.push(item)
                    }
                    else if(that.leftHeight == that.rightHeight) {
                        if(that.left.length > that.right.length) {
                            that.right.push(item)
                        } else {
                            that.left.push(item)
                        }
                    }
                    else {
                        that.right.push(item)
                    }
                    that.lockCount += 1
                    if(that.lockCount === data.length) {
                        that.lockCount = 0
                        that.lock = false
                    }
                }
            })
        },
        getList(data) {
            if(this.lock) {
                return
            }
            this.datas.concat(data)
            this.getSort(data)
        },
        toDetail(item) {
            this.$router.push({
                path: '/mobile/detail',
                query: {
                    CollectibleID: item.CollectibleID
                }
            })
        }
    }
}
</script>
<style scoped>
.pubu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: .2667rem;
    padding-right: .2667rem;
    background-color: #ffffff;
}
.list {
    width: 4.6rem;
    
    
}
.block {
    width: 100%;
    padding-bottom: .8rem;
}
.block img {
    display: block;
    width: 100%;
}
.block-title {
    font-size: .3733rem;
    color: #333333;
    height: 1.0666rem;
    line-height: .5333rem;
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: .2rem;
    margin-bottom: .2rem;
}
.block-info {
    font-size: .2933rem;
    color: #999999;
    line-height: .3733rem;
}
.block-info p {
    /* overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis; */
    margin-bottom: .0667rem;
}
.block-info p:last-child {
    margin-bottom: 0;
}
.pd0 {
    padding: 0;
}
.item-img {
    display: block;
    width: 100%;
}
</style>