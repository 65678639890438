<template>
  <div class="search">
    <Header title="藏品搜索" :icon="true"></Header>
    <div class="search-form">
      <div class="search-input">
        <el-input
          placeholder="选择场馆"
          v-model="OrgName"
          v-on:click.native="handleOrpsPicker"
        ></el-input>
        <img src="@/assets/img/z3.png" class="search-icon" />
      </div>
      <div class="search-category">
        <div class="search-input">
        <el-input
          placeholder="选择藏品分类"
          v-model="categoryOne"
          v-on:click.native="handlePicker"
        ></el-input>
        <img src="@/assets/img/z3.png" class="search-icon" />
      </div>
      <div class="search-input">
        <el-input
          placeholder="选择藏品二级分类"
          v-model="queryForm.CategoryName"
          v-on:click.native="handleSonPicker"
        ></el-input>
        <img src="@/assets/img/z3.png" class="search-icon" />
      </div>
      </div>
      <div class="search-input">
        <el-input placeholder="藏品名称" v-model="queryForm.Name"></el-input>
      </div>
      <div class="search-input">
        <el-input placeholder="作者" v-model="queryForm.Author"></el-input>
      </div>
      <div class="search-input">
        <el-input
          placeholder="创作年代"
          v-model="queryForm.CreationYear"
        ></el-input>
      </div>
      <button class="search-submit" @click="search">搜索</button>
    </div>
  </div>
</template>
<script>
import Header from "./component/header.vue";
import https from "@/utils/https";
/* eslint-disable */
// import weui,{ picker } from "@/utils/weui.min.js"
import weui, { picker } from "@/utils/weui.js";
import "@/utils/weui.css";
export default {
  components: { Header },
  data() {
    return {
      value: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      category: [], //
      categoryChildren: [],
      categoryOne: '',
      right: [],
      OrgName: "", //场馆名称
      queryForm: {
        OrgID: "", //收藏单位ID
        CategoryName: "", //藏品分类
        Name: "", //藏品名称
        Author: "", //作者
        CreationYear: "", //创作年代
      },
      orgs: [], //场馆
      tempCategory: "", //保存拉动分类时第一级分类
    };
  },
  created() {
    this.getCategoryList();
    this.getOrps();
    if (this.$route.query.ExhibitionID) {
      this.queryForm["ExhibitionID"] = this.$route.query.ExhibitionID;
    }
  },
  mounted() {},
  methods: {
    onChange() {},
    getCategoryList() {
      https({
        url: "/api/webapi/GetCategorys",
        method: "get",
        options: {
          message: false,
          loading: false,
        },
      }).then((res) => {
        let data = res.data;
        const newData = this.handleValue(data);
        this.category = newData;
      });
    },
    handleValue(data) {
      data.forEach((item) => {
        (item.label = item.name), (item.value = item.id);
        if (Object.prototype.hasOwnProperty.call(item, "childrens")) {
          item["childrens"].forEach((i) => {
            i.label = i.name;
            i.value = i.id;
          });
        }
      });

      return data;
    },
    handlePicker() {
      const that = this;
      picker(this.category, {
        // onChange: function (result) {
        //   // const obj = that.category.find((item) => {
        //   //   return item.id === result[0].id;
        //   // });
        //   // that.categoryChildren = obj["childrens"];
        //   // if (that.tempCategory !== result[0].id) {
        //   //   that.tempCategory = result[0].id;
        //   //   this.update(obj["childrens"], 1);
        //   //   return;
        //   // }
        // },
        onConfirm: function (result) {
          that.categoryOne = result[0]['name']
          if(Object.prototype.hasOwnProperty.call(result[0],'childrens') && result[0]['childrens'].length > 0) {
            console.log(result[0]['childrens'])
            that.categoryChildren = result[0]['childrens']
          } else {
            that.categoryChildren = []
            //没有二级分类时，一级分类就是搜索条件
            that.queryForm.CategoryName = result[0]['name']
          }
          // if (result[1] === null) {
          //   that.queryForm.CategoryName = result[0].name;
          // } else {
          //   that.queryForm.CategoryName = result[1].name;
          // }
        },
      });
    },
    //藏品二级分类
    handleSonPicker() {
      const that = this
      if(this.categoryChildren.length === 0) {
        this.$message({
          message: '此分类没有二级分类',
          type: 'warning'
        });
        return
      }
      picker(this.categoryChildren,{
        onConfirm: function(result) {
          that.queryForm.CategoryName = result[0]['name']
        }
      })
    },
    //获取场馆
    getOrps() {
      https({
        url: "/api/webapi/GetOrgs",
        method: "get",
        options: {
          message: false,
          loading: false,
        },
      }).then((res) => {
        res.data.forEach((item) => {
          item.label = item.OrgName;
          item.value = item.OrgID;
        });
        this.orgs = res.data;
      });
    },
    handleOrpsPicker() {
      const that = this;
      weui.picker(this.orgs, {
        onConfirm: function (result) {
          that.queryForm.OrgID = result[0].OrgID;
          that.OrgName = result[0].OrgName;
        },
      });
    },
    //搜索
    search() {
      this.$router.push({
        path: "/mobile/collect",
        query: this.queryForm,
      });
    },
  },
};
</script>
<style scoped>
.search {
  height: 100vh;
  background-color: #e3d5c5;
}
.search-form {
  padding-top: 1.3067rem;
  padding-left: 0.4rem;
}
</style>
<style>
.search-input {
  margin-bottom: 0.5333rem;
  position: relative;
}
.search-icon {
  position: absolute;
  width: 0.4rem;
  top: 50%;
  transform: translateY(-50%);
  right: 0.7rem;
}
.search .el-select .el-input__inner {
  width: 9.2rem;
  height: 0.8533rem;
  line-height: 0.8533rem;
  margin: 0 auto;
  border-radius: 0.4267rem;
  border: 0;
  outline: 0;
}
.search .el-input__inner {
  width: 9.2rem;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  border-radius: 6px;
  border: 0;
  outline: 0;
}
.search .el-input__icon {
  line-height: 0.8533rem;
}
.search-submit {
  display: block;
  width: 9.2rem;
  height: 1.0667rem;
  background: #118373;
  border-radius: 0.5333rem;
  margin: 1.3333rem auto 0;
  margin-left: 0;
  font-size: 0.48rem;
  color: #ffffff;
}
.search-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: .4rem;
}
.search-category .search-input {
  width: 48%;
}
.search .search-category .el-input__inner {
  width: 100%;
}
.search .search-category .search-icon {
  right: .32rem;
}

</style>