<template>
    <div>
        <Header title="展览详情" :icon="true"></Header>
        <div class="banner">
            <img :src="imgPath + detail.ImgPath" id="previewImg">
        </div>
        <div class="container">
            <p class="title">{{ detail.Title }}</p>
            <div class="intro">
                <p><span class="tip">展览时间</span><span>:</span>{{ detail.StartTime }} - {{ detail.EndTime }}</p>
                <p><span class="tip">主办方</span><span>:</span>{{ detail.Organizer }}</p>
            </div>
            <div class="line"></div>
            <div class="moredetail">
                <div class="tab">
                    <button :class="[current == 0 ? 'active':'']" @click="clickTab(0)">展览简介</button>
                    <button :class="[current == 1 ? 'active':'']" @click="clickTab(1)" v-if="isShow">藏品信息</button>
                </div>
                <div class="desc" v-if="current == 0" v-html="detail.Intro"></div>
                <div class="info" v-show="current == 1">
                    <List ref="list" :haspd="false" width="4.5"></List>
                    <!-- <p class="info-tip">尺寸</p>
                    <div class="info-desc">商山四皓 乙卯仲冬上浣 黄山寿写于海上</div>
                    <p class="info-tip">题跋释文</p>
                    <div class="info-desc">跋在中国画艺术中占有很重要的地位，是中国画构图的组成部分。一般说来，在书画、碑帖等前后题记的文字，均可称为题跋。写在书画或碑帖前面的文字称为“题”，写在后面的文字称为“跋”。</div>
                    <p class="info-tip">印鉴</p>
                    <div class="info-desc-img">
                        <img src="@/assets/img/10.png">
                        <img src="@/assets/img/10.png">
                        <img src="@/assets/img/10.png">
                        <img src="@/assets/img/10.png">
                        <img src="@/assets/img/10.png">
                    </div>
                    <p class="info-tip">印鉴释文</p>
                    <div class="info-desc">印鉴是用作印于文件上表示鉴定或签署的文具，一般印章会先沾上颜料再印上，有些是印于蜡或火漆上。</div> -->
                </div>
            </div>
        </div>
        <!-- <div class="imgdialog">
            <button>关闭</button>
        </div> -->
    </div>
</template>
<script>
import Header from './component/header.vue'
import https from '@/utils/https'
import List from './component/collectList.vue'
import { filterEmpty } from '@/utils/tool'
export default {
    components: {Header, List},
    data() {
        return {
            list: [],
            count: 0,
            current: 0,
            ExhibitionID: 0,
            detail: {},
            collect: [],
            queryForm: {
                ExhibitionID: '',
                OrgID: '',
                CategoryName: '',
                Name: '',
                Author: '',
                CreationYear: '',
                PageIndex: 1,
                PageSize: 10
            },
            isShow: true
        }
    },
    created() {
        this.ExhibitionID = this.$route.query.ExhibitionID
        this.queryForm.ExhibitionID = this.$route.query.ExhibitionID
        // this.isShow = this.$route.query.isShow
    },
    mounted() {
        const that = this
        this.getDetail()
        window.onscroll = function() {
            if(that.current == 1) {
                if (that.isScrollAtBottom()) {
                    // 执行到达底部的回调操作
                    if(that.count > that.list.length) {
                        that.getList()
                    }
                }
            } else {
                // console.log('没到达底部')
            }
        };
    },
    methods: {
        clickTab(index) {
            this.current = index
            if(index === 1) {
                // if(this.collect.length === 0) {
                //     this.getList()
                // }
                this.$router.push({
                    path: '/mobile/collect',
                    query: {
                        ExhibitionID: this.ExhibitionID
                    }
                })
            }
        },
        getList() {
            this.$nextTick(() => {
                const listStatus = this.$refs['list'].getStatus()
                if(listStatus) {
                    return
                }
                const dataForm = filterEmpty(this.queryForm)
                https({
                    url: '/api/webapi/Collectibles_GetCollectibles',
                    method: 'get',
                    data: dataForm,
                    options: {
                        message: false,
                        loading: true
                    }
                }).then(res => {
                    const data = res.data
                    this.list = this.list.concat(data.data)
                    if(data.data?.length > 0) {
                        this.$refs['list'].getList(data.data)
                    }
                    this.count = data.count
                    this.queryForm.PageIndex += 1
                })
            })
        },
        getDetail() {
            https({
                url: '/api/webapi/Exhibition_GetDetail',
                method: 'get',
                data: {
                    ExhibitionID: this.ExhibitionID
                }
            }).then(res => {
                this.detail = res.data
                this.isShow = res.data.StatusID == 2 ? true : false
            })
        },
        isScrollAtBottom() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 获取当前滚动位置
            const windowHeight = window.innerHeight; // 浏览器视口的高度
            const documentHeight = document.documentElement.scrollHeight; // 文档的总高度
            return scrollTop + windowHeight >= documentHeight; // 当滚动位置加上窗口高度大于等于文档高度时，表明已滚动到底部
        }
    }
}
</script>
<style scoped>
.banner {
    min-height: 3rem;
    position: relative;
}
.banner img {
    display: block;
    width: 100%;
}
.showbig {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 3.3333rem;
    height: 1.0667rem;
    background: rgba(0,0,0,.8);
    color: #ffffff;
    font-size: .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.showbig img {
    display: block;
    width: .5333rem;
    margin-right: .2rem;
}
.container {
    /* padding-left: .4rem;
    padding-right: .4rem; */
}
.container .title {
    font-size: .48rem;
    color: #333333;
    line-height: .64rem;
    margin-top: .4rem;
    padding-left: .4rem;
    padding-right: .4rem;
}
.intro {
    font-size: .3733rem;
    color: #333333;
    margin-top: .5333rem;
    padding-left: .4rem;
    padding-right: .4rem;
}
.intro p {
    height: .4rem;
    /* line-height: .4rem; */
    margin-bottom: .3067rem;
    font-size: .3733rem;
    color: #333333;
    display: flex;
}
.intro p span {
    display: inline-block;
    /* vertical-align: middle; */
    /* height: .4rem; */
    /* line-height: .4rem; */
}
.intro .tip {
    display: inline-block;
    width: 1.6rem;
    font-size: .3733rem;
    color: #999999;
    height: .4rem;
    /* line-height: .4rem; */
    text-align: justify;
    overflow: hidden;
}
.intro p span:nth-child(2) {
    margin-right: .4933rem;
}
.intro .tip::after {
    content: "";
    width: 100%;
    display: inline-block;
    height: 0;
    overflow: hidden;
}
.line {
    width: 100%;
    height: 1px;
    background-color: #E7E7E7;
    margin-top: .5333rem;
}
.moredetail {
    padding-left: .4rem;
    padding-right: .4rem;
    padding-bottom: 50px;
}
.moredetail .tab {
    display: flex;
    align-items: center;
    margin-top: .4rem;
    margin-bottom: .6667rem;
}
.moredetail .tab button {
    width: 3.2rem;
    height: .8533rem;
    line-height: .8533rem;
    text-align: center;
    background: #E0E0E0;
    border-radius: .0667rem .0667rem .0667rem .0667rem;
    font-size: .4rem;
    color: #333333;
    margin-right: .2667rem;
}
.moredetail .tab button.active {
    background: #E3D5C5;
}
.desc {
    font-size: .3733rem;
    color: #333333;
    line-height: .64rem;
}
.info-tip {
    padding-left: 16px;
    position: relative;
    font-size: .4rem;
    color: #333333;
}
.info-tip::before {
    content: "";
    width: .0533rem;
    height: .32rem;
    background: #118373;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}
.info-desc {
    font-size: .3733rem;
    color: #333333;
    line-height: .64rem;
    margin-bottom: .8rem;
}
.imgdialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.7);
    z-index: 9;
}
.imgdialog button {
    display: block;
    width: 120px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #FFFFFF;
    border-radius: .2667rem;
    font-size: 14px;
    color: #000000;
    margin: .4rem auto 0;
}
.info-desc-img {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .8rem;
}
.info-desc-img img {
    width: 1.8667rem;
    height: 1.8667rem;
    margin-right: .2667rem;
    margin-bottom: .1333rem;
}
.info-desc-img img:nth-child(4) {
    margin-right: 0;
}
</style>
