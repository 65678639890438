<template>
    <footer>
        <p :class="{'active': current == 0}" @click="goPage('/mobile/index')">首页</p>
        <p :class="{'active': current == 1}" @click="goPage('/mobile/collect')">藏品</p>
        <p :class="{'active': current == 2}" @click="goPage('/mobile/exhibit')">展览</p>
        <p :class="{'active': current == 3}" @click="goPage('/mobile/curation')">策展</p>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            current: 0
        }
    },
    mounted() {
        const path = this.$route.path
        if(path.includes('index')) {
            this.current = 0
        } else if(path.includes('collect')) {
            this.current = 1
        } else if(path.includes('exhibit')) {
            this.current = 2
        } else if(path.includes('curation')) {
            this.current = 3
        }
    },
    methods: {
        goPage(url) {
            this.$router.push({
                path: url
            })
        }
    }
}
</script>
<style scoped>
footer {
    max-width: 750px;
    margin: 0 auto;
    height: 50px;
    background: #F5F1E7;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-content: space-between;
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 14px;
    color: #878787;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    padding-left: 1.4933rem;
    padding-right: 1.4933rem;
}
footer p {
    /* margin-right: 2rem; */
}
footer p:last-child {
    margin-right: 0;
}
footer p.active {
    font-size: 18px;
    color: #118373;
}
</style>