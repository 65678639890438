<template>
    <div>
        <div class="listheader">
            <Header title="美术馆藏品公众服务展示系统"></Header>
            <!-- <div class="searchbar">
                <div class="searchbar-input">
                    <img src="@/assets/img/z6.png" >
                </div>
            </div> -->
        </div>
        <div class="tab">
            <button :class="{'active': current == 2}" @click="tab(2)">正在展出</button>
            <button :class="{'active': current == 1}" @click="tab(1)">即将展出</button>
            <button :class="{'active': current == 3}" @click="tab(3)">展览回顾</button>
        </div>
        <div class="listcontainer">
            <List ref="list" :isShow="isShow"></List>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from './component/header.vue'
import Footer from './component/footer.vue'
import List from './component/list.vue'
import https from '@/utils/https'
import { filterEmpty } from '@/utils/tool'
export default {
    components: {Header,Footer,List},
    data() {
        return {
            list: [],
            queryForm: {
                StatusID: 2,
                PageIndex: 1,
                PageSize: 10
            },
            count: 0,
            current: 2,
            isShow: true
        }
    },
    mounted() {
        const that = this
        this.getList()
        // 监听滚动事件
        window.onscroll = function() {
            if (that.isScrollAtBottom()) {
                console.log("已滚动到底部");
                // 执行到达底部的回调操作
                if(that.count > that.list.length) {
                    that.getList()
                }
            }
        };
    },
    methods: {
        getList() {
            this.$nextTick(() => {
                const listStatus = this.$refs['list'].getStatus()
                if(listStatus) {
                    return
                }
                const dataForm = filterEmpty(this.queryForm)
                console.log(dataForm)
                https({
                    url: '/api/webapi/Exhibition_GetList',
                    method: 'get',
                    data: dataForm,
                    options: {
                        message: false,
                        loading: true
                    }
                }).then( res => {
                    this.list = this.list.concat(res.data)
                    if(res.data.length > 0) {
                        this.$refs['list'].getList(res.data)
                    }
                    this.count = res.count
                    this.queryForm.PageIndex += 1 
                })
            })
        },
        isScrollAtBottom() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 获取当前滚动位置
            const windowHeight = window.innerHeight; // 浏览器视口的高度
            const documentHeight = document.documentElement.scrollHeight; // 文档的总高度
            return scrollTop + windowHeight >= documentHeight; // 当滚动位置加上窗口高度大于等于文档高度时，表明已滚动到底部
        },
        tab(index) {
            this.current = index
            this.list = []
            this.queryForm = {
                StatusID: index,
                PageIndex: 1,
                PageSize: 10
            }
            if(index != 2 ) {
                this.isShow = false
            }
            this.$refs['list'].initList()
            this.getList()
        }
    }
}
</script>
<style scoped>
.listheader {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
.searchbar {
    height: 1.0667rem;
    background-color: #F5F1E7;
    margin-bottom: .2667rem;
}
.searchbar-input {
    width: 9.4667rem;
    height: .7467rem;
    background: #FFFFFF;
    border-radius: .3733rem;
    margin: 0 auto;
    position: relative;
}
.searchbar-input img {
    position: absolute;
    width: .4667rem;
    top: 50%;
    transform: translateY(-50%);
    right: .4rem;
}
.listcontainer {
    padding-bottom: 2rem;
}
.tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .3333rem;
    margin-bottom: .4rem;
}
.tab button {
    width: 2.6667rem;
    height: .7467rem;
    background: #CECBC7;
    border-radius: .3733rem;
    font-size: .3733rem;
    color: #333333;
    margin-right: .2667rem;
}
.tab button:last-child {
    margin-right: 0;
}
.tab button.active {
    background: #118373;
    color: #ffffff;
}
</style>