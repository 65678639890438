<template>
    <div>
        <Header></Header>
        <div class="swiper-container my-swiper1">
          <div class="swiper-wrapper">
            <div
                class="swiper-slide"
                v-for="item in list"
                :key="item.DataID"
                @click="toDetail(item)">
                <img class="img" :src="item.ImgPath" :alt="item.Title">
                <p class="swiper-text">{{ item.Title }}</p>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import '@/utils/flexible'
import Header from './component/header.vue'
import Footer from './component/footer.vue' 
import https from '@/utils/https'
import Swiper from 'swiper'
// import { Swiper, SwiperSlide } from 'swiper';
// import 'swiper/css/swiper.css'
import 'swiper/css/swiper.min.css';
export default {
    components: {Header,Footer},
    data() {
        return {
            list: []
        }
    },
    created() {
        this.getbanner()
    },
    methods: {
        toDetail (data) {
      if (data.ReType === 1) {
        this.$router.push({
          path: '/mobile/detail',
          query: {
            CollectibleID: data.ReID
          }
        })
      }
      if (data.ReType === 2) {
        this.$router.push({
          path: '/mobile/exhibitdetail',
          query: {
            ExhibitionID: data.ReID
          }
        })
      }
      if (data.ReType === 3) {
        window.open(data.Link, '_blank')
      }
    },
        getbanner() {
            https({
                url: '/api/webapi/GetBanners',
                method: 'get',
                data: {
                    TypeID: 1
                },
                options: {
                    message: false,
                    loading: false
                }
            }).then(res => {
                const arr = [...res.data]
                arr.forEach(item => {
                  if(!(item.ImgPath.startsWith('http'))) {
                    item.ImgPath = this.imgPath + item.ImgPath
                  }
                })
                this.list = arr
                // console.log(this.list)
                this.$nextTick(() => {
                if (this.list.length > 0) {
                    new Swiper(".my-swiper1",
                    {
                      autoplay: {
                        delay: 1000,//1秒切换一次
                      },
                    }
              //       {
              //         pagination: {
              //   el: '.my-swiper1 .swiper-pagination'
              // }
              //       }
                  )
                }
                })
            })
        }
    }
}
</script>
<style scoped>
/* .swiper-slide img {
  display: block;
  width: 100%;
} */
.my-swiper1 {
  height: calc( 100vh - 90px );
  background-color: #e3d5c6;
}
.my-swiper1 .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.my-swiper1 .swiper-slide img {
  display: block;
  /* height: 100%; */
  width: 7.7067rem;
  max-height: 10.8533rem;
  object-fit: cover;
  /* background-color: #e1aa26; */
  border: .0667rem solid #e1aa26;
	box-shadow: 0px 5px 11px 2px 
		rgba(91, 79, 61, 0.63);
}
.swiper-text {
  color: #333333;
  font-size: .3733rem;
  width: 7.7067rem;
  /* margin-top: .5333rem; */
  margin: .5333rem auto ;
  text-align: center;
  padding-left: .5333rem;
  padding-right: .5333rem;
  word-break: break-all;
}
</style>
<style>
.swiper-pagination-bullet-active {
  background: #ffffff;
}
</style>