<template>
  <el-select
    :value="modelValue"
    :popper-append-to-body="true"
    :placeholder="placeholder"
    :disabled="disabled"
    clearable
    @change="select"
    :style="{width: width ? width + 'px' : '100%','--my-select-height': height ? height + 'px' : '40px'}">
    <el-option
      v-for="item in data"
      :key="item[valueName ? valueName : 'value']"
      :label="item[labelName  ? labelName  : 'label']"
      :value="item[valueName ? valueName : 'value']"
    />
  </el-select>
</template>

<script>
export default {
  name: "mySelect",
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: {
    data: Array,
    placeholder: String,
    width: [Number, String],
    height: [Number, String],
    labelName: String,
    valueName: String,
    disabled: Boolean,
    modelValue: {
      type: [Number, String]
    }
  },
  data () {
    return {
      value: null,
      style: null
    }
  },
  methods: {
    select (val) {
      this.$emit("change", val)
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.el-select {
  .el-input__inner{
    height: var(--my-select-height);
    padding: 0 19px;
    font-size: 16px;
    border-radius: 20px;
    border: none;
    &::placeholder{
      color: #999999;
    }
  }
  // .el-input__icon{
  //   display: none;
  // }
  // .el-input__suffix-inner{
  // }
  .el-input .el-select__caret{
    font-size: 16px;
    color: #333333;
  }
  .el-icon-arrow-up:before{
    content: '\e78f';
  }
  .el-input__suffix{
    right: 19px;
  }
}
</style>
