import Vue from "vue"
import VueRouter from "vue-router"

import pcContainer from '@/views/pc/container.vue'
import pcIndex from '@/views/pc/index.vue'
import pcCollection from '@/views/pc/collection.vue'
import pcExhibition from '@/views/pc/exhibition.vue'
import pcDetail from '@/views/pc/detail.vue'
import pcCuration from '@/views/pc/curation.vue'

import mIndex from '@/views/mobile/index.vue'
import collect from '@/views/mobile/collect.vue'
import exhibit from '@/views/mobile/exhibit.vue'
import detail from '@/views/mobile/detail.vue'
import exhibitdetail from '@/views/mobile/exhibitdetail.vue'
import search from '@/views/mobile/search.vue'
import curation from '@/views/mobile/curation.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
    component: pcContainer,
    children: [
      {
        path: 'index',
        name: 'pcIndex',
        component: pcIndex
      },{
        path: 'collection',
        name: 'pcCollection',
        component: pcCollection
      },{
        path: 'exhibition',
        name: 'pcExhibition',
        component: pcExhibition
      },{
        path: 'exhibition/collection',
        name: 'pcExhibitionCollection',
        component: pcCollection
      },
      {
        path: 'exhibition/collection/detail',
        name: 'pcCollectionDetail',
        component: pcDetail
      },
      {
        path: 'collection/detail',
        name: 'pcCollectionDetail',
        component: pcDetail
      },
      {
        path: 'curation',
        name: 'pcCuration',
        component: pcCuration
      }
    ]
  },
  {
    path: '/mobile/index',
    component: mIndex
  },
  {
    path: '/mobile/exhibit',
    component: exhibit
  },
  {
    path: '/mobile/detail',
    component: detail
  },
  {
    path: '/mobile/search',
    component: search
  },
  {
    path: '/mobile/collect',
    component: collect
  },
  {
    path: '/mobile/exhibitdetail',
    component: exhibitdetail
  },
  {
    path: '/mobile/curation',
    component: curation
  }
]

const router = new VueRouter({
  routes
})

export default router