<template>
  <div class="main-container my-main">
    <router-view></router-view>
    <div class="my-main-container">
      <div class="my-breadcrumb" v-if="id !== 0">
        <router-link to="/exhibition" class="breadcrumb-item active el-icon-">展览</router-link>
        <span class="breadcrumb-item">{{ detail.Title }}</span>
      </div>
      <div class="top my-screen">
        <my-select
          width="240"
          :data="orgsList"
          labelName="OrgName"
          valueName="OrgID"
          v-model="getListForm.OrgID"
          placeholder="选择场馆"></my-select>
        <el-cascader
          :options="categorysList"
          :props="{
            value: 'name',
            label: 'name',
            checkStrictly: true,
            emitPath: false
          }"
          @change="cascaderChange"
          popper-class="my-cascader-popper"
          clearable
          placeholder="选择藏品分类"
          v-model="getListForm.CategoryName"></el-cascader>
        <el-input class="w-200 w-40" v-model="getListForm.Name" placeholder="藏品名称"></el-input>
        <el-input class="w-200 w-40" v-model="getListForm.Author" placeholder="作者"></el-input>
        <el-input class="w-200 w-40" v-model="getListForm.CreationYear" placeholder="创作年代"></el-input>
        <el-button @click="search" type="primary" class="my-button w-100 w-20 h-40 radius search-btn">搜索</el-button>
      </div>
      <div class="my-list1">
        <div
          class="item"
          v-for="item,index in list"
          :key="index">
          <div
            class="item-children hover-color"
            @click="toDetail(chil.CollectibleID)"
            v-for="chil in item"
            :key="chil.CollectibleID">
            <el-image :src="chil.picture" class="item-img">
              <div slot="error" class="image-error">
                <div class="image-error-contianer">
                  <i class="el-icon-picture-outline image-error-icon"></i>
                  <p class="image-error-text">加载失败</p>
                </div>
              </div>
            </el-image>
            <div class="item-info">
              <p class="item-title">{{ chil.name }}</p>
              <p class="item-text">
                <span>分类：</span>
                <span>
                  <template v-if="chil.categoryOne">
                    {{ chil.categoryOne }}
                  </template>
                  <template v-if="chil.categoryTwo || cascaderIndex === 2">
                    /{{ chil.categoryTwo }}
                  </template>
                </span>
              </p>
              <p class="item-text">
                <span>作者：</span>
                <span>{{ chil.author }}</span>
              </p>
              <p class="item-text">
                <span>创作年代：</span>
                <span>{{ chil.creationYear }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mySelect from '@/components/mySelect.vue'
export default {
  components: { mySelect },
  name: 'collection',
  data () {
    return {
      id: 0,
      list1: [
        {
          img: require('@/assets/img/2.png'),
          title: '藏品标题名称好多字文字超出行宽换行显示',
          type: '二级分类名称',
          author: '作者名字',
          year: '1947-XXXX'
        },{
          img: require('@/assets/img/3.png'),
          title: '藏品标题名称好多字文字超出行宽换行显示',
          type: '二级分类名称',
          author: '作者名字',
          year: '1947-XXXX'
        },{
          img: require('@/assets/img/4.png'),
          title: '藏品标题名称好多字文字超出行宽换行显示',
          type: '二级分类名称',
          author: '作者名字',
          year: '1947-XXXX'
        },{
          img: require('@/assets/img/5.png'),
          title: '藏品标题名称好多字文字超出行宽换行显示',
          type: '二级分类名称',
          author: '作者名字',
          year: '1947-XXXX'
        },{
          img: require('@/assets/img/6.png'),
          title: '藏品标题名称好多字文字超出行宽换行显示',
          type: '二级分类名称',
          author: '作者名字',
          year: '1947-XXXX'
        },{
          img: require('@/assets/img/2.png'),
          title: '藏品标题名称好多字文字超出行宽换行显示',
          type: '二级分类名称',
          author: '作者名字',
          year: '1947-XXXX'
        },{
          img: require('@/assets/img/3.png'),
          title: '藏品标题名称好多字文字超出行宽换行显示',
          type: '二级分类名称',
          author: '作者名字',
          year: '1947-XXXX'
        },{
          img: require('@/assets/img/4.png'),
          title: '藏品标题名称好多字文字超出行宽换行显示',
          type: '二级分类名称',
          author: '作者名字',
          year: '1947-XXXX'
        }
      ],
      list: [],
      orgsList: [],
      categorysList: [],
      getListForm: {
        OrgID: null,
        CategoryName: null,
        Name: '',
        Author: '',
        CreationYear: '',
        PageIndex: 1,
        PageSize:  10
      },
      detail: {},
      cascaderIndex: 0
    }
  },
  mounted () {
    this.id = parseInt(this.$route.query.id) || 0
    if (this.id !== 0) {
      this.getListForm.ExhibitionID = this.id
      this.getDetail()
    }
    window.addEventListener("scroll", this.handleScroll);
    this.getOrgs()
    this.getCategorys()
    this.getList()
  },
  methods: {
    toDetail (id) {
      let data = {
        id: id
      }
      if (this.id !== 0) {
        data.ExhibitionID = this.id
      }
      this.$router.push({
        path: this.$route.path + '/detail',
        // path: '/collection/detail',
        query: data
      })
    },
    getDetail () {
      this.https({
        url: '/api/webapi/Exhibition_GetDetail',
        method: 'get',
        data: { ExhibitionID: this.id },
        options: {
          message: false,
          loading: false
        }
      }).then(({ data }) => {
        this.detail = data
      })
    },
    search () {
      this.getListForm.PageIndex = 1
      this.list = []
      this.getList()
    },
    getList () {
      let url = '/api/webapi/Collectibles_GetList'
      if (this.id !== 0) {
        url = '/api/webapi/Collectibles_GetCollectibles'
      }
      this.https({
        url: url,
        method: 'get',
        data: this.getListForm,
        options: {
          message: false,
          loading: true
        }
      }).then(({ data }) => {
        if (this.getListForm.PageIndex === 1) {
          this.list = this.handleData(data)
        } else {
          let list = this.handleData(data)
          this.list.map((item,index) => {
            item.push(...list[index])
          })
        }
      })
    },
    getOrgs () {
      this.https({
        url: '/api/webapi/GetOrgs',
        method: 'get',
        options: {
          message: false,
          loading: false
        }
      }).then(({ data }) => {
        this.orgsList = data
      })
    },
    getCategorys () {
      this.https({
        url: '/api/webapi/GetCategorys',
        method: 'get',
        options: {
          message: false,
          loading: false
        }
      }).then(({ data }) => {
        data.map(item => {
          item.children = item.childrens
        })
        this.categorysList = data
      })
    },
    cascaderChange (e) {
      this.cascaderIndex = e.length
    },
    handleData (data) {
      let list = JSON.parse(JSON.stringify(data))
      let arr = [[], [], [], [], []]
      list.map((item, index) => {
        arr[index % 5].push(item)
      })
      return arr
    },
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.getListForm.PageIndex = this.getListForm.PageIndex + 1
        this.getList()
      }
    }
  },
  watch: {
    '$route' () {
      this.id = parseInt(this.$route.query.id) || 0
      this.getListForm = {
        OrgID: null,
        CategoryName: null,
        Name: '',
        Author: '',
        CreationYear: '',
        PageIndex: 1,
        PageSize:  10
      }
      this.getList()
    }
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>

</style>