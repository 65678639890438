<template>
  <div class="main-container">
    <div class="my-select">
      <span
        class="select-item"
        :class="{active: getListForm.StatusID === item.id}"
        @click="getListForm.StatusID = item.id"
        v-for="item in selectList"
        :key="item.id">{{ item.title }}</span>
    </div>
    <div class="list">
      <div
        class="item hover-color"
        v-for="item in list"
        :key="item.ExhibitionID">
        <img class="item-img" :src="baseURL + item.ImgPath">
        <div class="item-info">
          <p class="item-title">{{ item.Title }}</p>
          <p class="item-text">展览时间：{{ item.StartTime }} 至 {{ item.EndTime }}</p>
          <p class="item-text">主办方：{{ item.Organizer }}</p>
          <p class="item-content">{{ item.Intro }}</p>
          <router-link
            :to="'/exhibition/collection?id=' + item.ExhibitionID"
            class="item-more"
            v-if="getListForm.StatusID === 2">
            <img class="item-icon" src="@/assets/img/8.png">
            展览详情
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'exhibition',
  data () {
    return {
      selectList: [
        {
          title: '正在展出',
          id: 2
        },{
          title: '即将展出',
          id: 1
        },{
          title: '展览回顾',
          id: 3
        }
      ],
      list: [],
      getListForm: {
        StatusID: 2,
        PageIndex: 1,
        PageSize: 10
      }
    }
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll);
    this.getList()
  },
  methods: {
    getList () {
      this.https({
        url: '/api/webapi/Exhibition_GetList',
        method: 'get',
        data: this.getListForm,
        options: {
          message: false,
          loading: true
        }
      }).then(({ data }) => {
        this.list = [...this.list, ...data]
      })
    },
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.getListForm.PageIndex = this.getListForm.PageIndex + 1
        this.getList()
      }
    }
  },
  watch: {
    'getListForm.StatusID' () {
      this.list = []
      this.getListForm.PageIndex = 1
      this.getList()
    }
  },  
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
.my-select{
  display: flex;
  padding: 17px 0 23px;
  .select-item{
    width: 140px;
    line-height: 40px;
    text-align: center;
    display: block;
    background: #FFFFFF;
    border-radius: 20px;
    cursor: pointer;
    transition: all .1s;
    &:hover,&.active{
      background: #118373;
      color: #fff;
    }
    & + .select-item{
      margin-left: 10px;
    }
  }
}
.list{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .item{
    width: 690px;
    background: #FFFFFF;
    display: flex;
    align-items: flex-start;
    padding: 22px 20px;
    margin-bottom: 20px;
  }
  .item-img{
    width: 120px;
    height: 180px;
    flex-shrink: 0;
    margin-right: 28px;
  }
  .item-info{
    flex-grow: 1;
    width: 0;
  }
  .item-title{
    font-size: 18px;
    line-height: 24px;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 13px;
  }
  .item-text{
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .item-content{
    margin-top: 14px;
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 72px;
  }
  .item-more{
    margin-top: 29px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #118373;
    .item-icon{
      width: 27px;
      margin-right: 13px;
    }
  }
}
</style>