<template>
  <div>
    <Header title="藏品详情" :icon="true"></Header>
    <div class="banner">
      <img :src="detail.picture" />
      <div class="swiper-container my-swiper1" style="display: none">
        <div class="swiper-wrapper" id="previewImg">
          <div
            class="swiper-slide"
            v-for="(item, index) in pictures"
            :key="index"
          >
            <img class="img" :src="item" />
          </div>
        </div>
      </div>
      <div class="showbig" @click="previewImg">
        <img src="@/assets/img/z2.png" id="" />
        查看大图
      </div>
    </div>
    <div class="container">
      <p class="title">
        {{ detail.name }}
      </p>
      <div class="intro">
        <p><span class="tip">作者</span><span>:</span>{{ detail.author }}</p>
        <p>
          <span class="tip">藏品类别</span><span>:</span>{{ detail.categoryOne
          }}{{ detail.categoryTwo ? "-" + detail.categoryTwo : ""
          }}{{ detail.categoryThree ? "-" + detail.categoryThree : ""
          }}{{ detail.categoryFour ? "-" + detail.categoryFour : "" }}
        </p>
        <p>
          <span class="tip">收藏单位</span><span>:</span
          >{{ detail.courtOrgName }}
        </p>
        <p>
          <span class="tip">创作年代</span><span>:</span
          >{{ detail.creationYear }}
        </p>
        <p>
          <span class="tip">入藏日期</span><span>:</span
          >{{ detail.collectionDate }}
        </p>
        <p>
          <span class="tip tipmore">藏品登记号</span><span>:</span
          >{{ detail.registerNo }}
        </p>
        <p>
          <span class="tip">实际数量</span><span>:</span>{{ detail.realAmount }}
        </p>
        <p>
          <span class="tip">藏品来源</span><span>:</span>{{ detail.source }}
        </p>
        <p>
          <span class="tip">价值级别</span><span>:</span>{{ detail.valueLevel }}
        </p>
      </div>
      <div class="line"></div>
      <div class="moredetail">
        <div class="tab">
          <button :class="[current == 0 ? 'active' : '']" @click="clickTab(0)">
            作品简介
          </button>
          <button :class="[current == 1 ? 'active' : '']" @click="clickTab(1)">
            更多信息
          </button>
        </div>
        <div class="info zp-info" v-if="current == 0">
          <p
            class="info-tip"
            v-if="detail.authorNotes && detail.authorNotes != ''"
          >
            作者简介
          </p>
          <div
            class="desc"
            v-html="detail.authorNotes"
            v-if="detail.authorNotes && detail.authorNotes != ''"
          ></div>
          <p
            class="info-tip"
            v-if="detail.description && detail.description != ''"
          >
            作品简介
          </p>
          <div
            class="desc"
            v-html="detail.description"
            v-if="detail.description && detail.description != ''"
          ></div>
          <p
            class="info-tip"
            v-if="detail.historical && detail.historical != ''"
          >
            历史渊源
          </p>
          <div
            class="desc"
            v-html="detail.historical"
            v-if="detail.historical && detail.historical != ''"
          ></div>
          <p class="info-tip" v-if="detail.academic && detail.academic != ''">
            学术成果
          </p>
          <div
            class="desc"
            v-html="detail.academic"
            v-if="detail.academic && detail.academic != ''"
          ></div>
        </div>

        <div class="info" v-if="current == 1">
          <p class="info-tip" v-if="detail.texture">质地</p>
          <div class="info-desc" v-if="detail.texture">
            {{ detail.texture }}
          </div>
          <p class="info-tip" v-if="detail.morphotype">形态形制</p>
          <div class="info-desc" v-if="detail.morphotype">
            {{ detail.morphotype }}
          </div>
          <p class="info-tip" v-if="detail.size">尺寸</p>
          <div class="info-desc" v-if="detail.size">{{ detail.size }}</div>
          <p class="info-tip" v-if="detail.titleNo">题名</p>
          <div class="info-desc" v-if="detail.titleNo">
            {{ detail.titleNo
            }}<span v-if="detail.titleParaphrase != ''"
              >（释文：{{ detail.titleParaphrase }}）</span
            >
          </div>
          <p class="info-tip" v-if="detail.inscriptNo">款识</p>
          <div class="info-desc" v-if="detail.inscriptNo">
            {{ detail.inscriptNo
            }}<span v-if="detail.inscriptParaphrase != ''"
              >（释文：{{ detail.inscriptParaphrase }}）</span
            >
          </div>
          <p class="info-tip" v-if="detail.prefaceNo">题跋</p>
          <div class="info-desc" v-if="detail.prefaceNo">
            {{ detail.prefaceNo
            }}<span v-if="detail.prefaceParaphrase != ''"
              >（释文：{{ detail.prefaceParaphrase }}）</span
            >
          </div>
          <p class="info-tip" v-if="detail.epigraphNo">铭文</p>
          <div class="info-desc" v-if="detail.epigraphNo">
            {{ detail.epigraphNo
            }}<span v-if="detail.epigraphParaphrase != ''"
              >（释文：{{ detail.epigraphParaphrase }}）</span
            >
          </div>
          <p class="info-tip" v-if="detail.labelNo">题鉴</p>
          <div class="info-desc" v-if="detail.labelNo">
            {{ detail.labelNo
            }}<span v-if="detail.labelParaphrase != ''"
              >（释文：{{ detail.labelParaphrase }}）</span
            >
          </div>
          <!-- <p class="info-tip" v-if="detail.prefaceParaphrase">题跋释文</p>
          <div class="info-desc" v-if="detail.prefaceParaphrase" v-html="detail.prefaceParaphrase"></div> -->
          <p class="info-tip" v-if="detail.stampNo">印鉴</p>
          <div class="info-desc" v-if="detail.stampNo">
            {{ detail.stampNo
            }}<span v-if="detail.stampParaphrase != ''"
              >（释文：{{ detail.stampParaphrase }}）</span
            >
          </div>
          <!-- <div class="info-desc-img">
            <img src="@/assets/img/10.png" />
            <img src="@/assets/img/10.png" />
            <img src="@/assets/img/10.png" />
            <img src="@/assets/img/10.png" />
            <img src="@/assets/img/10.png" />
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="imgdialog">
            <button>关闭</button>
        </div> -->
  </div>
</template>
<script>
import Header from "./component/header.vue";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs/dist/viewer";
import https from "@/utils/https";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  components: { Header },
  data() {
    return {
      current: 0,
      previewImgData: null,
      isShow: false,
      CollectibleID: 0,
      detail: {},
      pictures: [],
      interval: null,
      currentIndex: 0,
    };
  },
  created() {
    this.CollectibleID = this.$route.query.CollectibleID;
  },
  mounted() {
    this.previewImgData = new Viewer(document.querySelector("#previewImg"), {
      button: false,
      title: false,
      toolbar: false,
      navbar: false,
      show: () => {
        this.isShow = true;
        this.previewImgData.update();
        // this.$nextTick(() => {
        //   var viewerMove = document.querySelector(".viewer-move");
        //   viewerMove.addEventListener("touchstart", function () {
        //     clearInterval(this.interval);
        //     this.interval = null;
        //   });
        //   viewerMove.addEventListener("touchend", function () {
        //     this.interval = setInterval(function () {
        //       this.previewImgData.next(); // 切换到下一张图片
        //     }, 3000); // 每3秒切换一次
        //   });
        // });
      },
      hide: () => {
        this.isShow = false;
        clearInterval(this.interval)
        this.interval = null
      },
      viewed: (event) => {
        const that = this
        clearInterval(this.interval)
        this.interval = null
        this.currentIndex = event.detail.index
        this.interval = setInterval(function () {
          if (that.currentIndex == that.pictures.length - 1) {
            that.previewImgData.update();
            that.currentIndex = 0;
          } else {
            that.currentIndex++;
            that.previewImgData.next();
          }
          that.touchPause();
        }, 3000); // 每3秒切换一次
      }
    });
    this.getDetail();
  },
  methods: {
    clickTab(index) {
      this.current = index;
    },
    close() {
      this.previewImgData.hide();
    },
    previewImg() {
      const that = this;
      this.previewImgData.show();
      if (this.pictures.length > 1) {
        that.touchPause();
        this.interval = setInterval(function () {
          if (that.currentIndex == that.pictures.length - 1) {
            that.previewImgData.update();
            that.currentIndex = 0;
          } else {
            that.currentIndex++;
            that.previewImgData.next();
          }
          that.touchPause();
        }, 3000); // 每3秒切换一次
      }
    },
    touchPause() {
      const that = this;
      this.$nextTick(() => {
        let viewerMove = document.querySelector(".viewer-canvas");
        let viewMoveImg = viewerMove.getElementsByTagName("img")[0];
        viewMoveImg.addEventListener("touchstart", function () {
          clearInterval(that.interval);
          that.interval = null;
        });
        viewMoveImg.addEventListener("touchend", function () {
          clearInterval(that.interval);
          that.interval = null;
          that.interval = setInterval(function () {
            if (that.currentIndex == that.pictures.length - 1) {
              that.previewImgData.update();
              that.currentIndex = 0;
            } else {
              that.currentIndex++;
              that.previewImgData.next();
            }
            that.touchPause();
          }, 3000); // 每3秒切换一次
        });
      });
    },
    getDetail() {
      https({
        url: "/api/webapi/Collectibles_GetDetail",
        method: "get",
        data: {
          CollectibleID: this.CollectibleID,
        },
      }).then((res) => {
        this.detail = res.data;
        // if(this.detail.description && this.detail.description != '') {
        //   this.current = 0
        // } else {
        //   this.current = 1
        // }
        this.pictures = this.detail.pictures.split(",");
        this.pictures.push('https://cp.zjam.org.cn/fs/4001-6000/4839-A-1.jpg')
        console.log(this.pictures);
        this.$nextTick(() => {
          new Swiper(".my-swiper1");
        });
      });
    },
  },
};
</script>
<style scoped>
.banner {
  min-height: 3rem;
  position: relative;
}
.banner img {
  display: block;
  width: 100%;
}
.showbig {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3.3333rem;
  height: 1.0667rem;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  font-size: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.showbig img {
  display: block;
  width: 0.5333rem;
  margin-right: 0.2rem;
}
.container {
  /* padding-left: .4rem;
    padding-right: .4rem; */
}
.container .title {
  font-size: 0.48rem;
  color: #333333;
  line-height: 0.64rem;
  margin-top: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.intro {
  font-size: 0.3733rem;
  color: #333333;
  margin-top: 0.5333rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.intro p {
  height: 0.4rem;
  /* line-height: .4rem; */
  margin-bottom: 0.3067rem;
  font-size: 0.3733rem;
  color: #333333;
  display: flex;
}
.intro p span {
  display: inline-block;
  /* vertical-align: middle; */
  /* height: .4rem; */
  /* line-height: .4rem; */
}
.intro .tip {
  display: inline-block;
  width: 1.6rem;
  font-size: 0.3733rem;
  color: #999999;
  height: 0.4rem;
  /* line-height: .4rem; */
  text-align: justify;
  overflow: hidden;
}
.intro .tipmore {
  width: 1.9rem;
  flex-shrink: 0;
}
.intro p span:nth-child(2) {
  margin-right: 0.4933rem;
}
.intro .tip::after {
  content: "";
  width: 100%;
  display: inline-block;
  height: 0;
  overflow: hidden;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
  margin-top: 0.5333rem;
}
.moredetail {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 50px;
}
.moredetail .tab {
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  margin-bottom: 0.6667rem;
}
.moredetail .tab button {
  width: 3.2rem;
  height: 0.8533rem;
  line-height: 0.8533rem;
  text-align: center;
  background: #e0e0e0;
  border-radius: 0.0667rem 0.0667rem 0.0667rem 0.0667rem;
  font-size: 0.4rem;
  color: #333333;
  margin-right: 0.2667rem;
}
.moredetail .tab button.active {
  background: #e3d5c5;
}
.desc {
  font-size: 0.3733rem;
  color: #333333;
  line-height: 0.64rem;
}
.info-tip {
  padding-left: 16px;
  position: relative;
  font-size: 0.4rem;
  color: #333333;
}
.info-tip::before {
  content: "";
  width: 0.0533rem;
  height: 0.32rem;
  background: #118373;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.info-desc {
  font-size: 0.3733rem;
  color: #333333;
  line-height: 0.64rem;
  margin-bottom: 0.8rem;
}
.imgdialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
}
.imgdialog button {
  display: block;
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #ffffff;
  border-radius: 0.2667rem;
  font-size: 14px;
  color: #000000;
  margin: 0.4rem auto 0;
}
.info-desc-img {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.8rem;
}
.info-desc-img img {
  width: 1.8667rem;
  height: 1.8667rem;
  margin-right: 0.2667rem;
  margin-bottom: 0.1333rem;
}
.info-desc-img img:nth-child(4) {
  margin-right: 0;
}
.my-swiper1 .swiper-slide img {
  display: block;
  width: 100%;
}
.zp-info .info-tip {
  margin-top: 40px;
  margin-bottom: 20px;
}
</style>
