<template>
    <div>
        <Header></Header>
        <div class="curation">
            <div class="default">
                <img src="@/assets/img/z7.png">
                <p>正在建设中，敬请期待！</p>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from './component/header.vue'
import Footer from './component/footer.vue'
export default {
    components: {Header,Footer},
    data() {
        return {}
    }
}
</script>
<style scoped >
.curation {
    height: calc( 100vh - 90px );
    background-color: #e3d5c6;
    overflow: hidden;
}
.curation .default {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-top: 4rem;
}
.curation .default img {
    display: block;
    width: 4.76rem;
	height: 3.4rem;
}
.curation .default p {
    text-align: center;
    color: #333333;
    font-size: .3733rem;
    margin-top: .8667rem;
}
</style>