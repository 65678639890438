<template>
    <div>
        <div class="listheader">
            <Header title="美术馆藏品公众服务展示系统"></Header>
            <div class="searchbar" @click="toSearch">
                <div class="searchbar-input">
                    {{ this.queryForm.Name }}
                    <img src="@/assets/img/z6.png" >
                </div>
            </div>
        </div>
        <div class="listcontainer">
            <List ref="list"></List>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from './component/header.vue'
import Footer from './component/footer.vue'
import List from './component/collectList.vue'
import https from '@/utils/https'
import { filterEmpty } from '@/utils/tool'
export default {
    components: {Header,Footer,List},
    data() {
        return {
            list: [],
            queryForm: {
                OrgID: '',
                CategoryName: '',
                Name: '',
                Author: '',
                CreationYear: '',
                PageIndex: 1,
                PageSize: 10
            },
            count: 0,
            url: '/api/webapi/Collectibles_GetList'///api/webapi/Collectibles_GetCollectibles
        }
    },
    created() {
        this.getParams('OrgID')
        this.getParams('CategoryName')
        this.getParams('Name')
        this.getParams('Author')
        this.getParams('CreationYear')
        this.getParams('ExhibitionID')
        if(this.$route.query['ExhibitionID']) {
            this.url = '/api/webapi/Collectibles_GetCollectibles'
        } else {
            this.url = '/api/webapi/Collectibles_GetList'
        }
    },
    mounted() {
        const that = this
        this.getList()
        // 监听滚动事件
        window.onscroll = function() {
            if (that.isScrollAtBottom()) {
                console.log("已滚动到底部");
                // 执行到达底部的回调操作
                if(that.count > that.list.length) {
                    that.getList()
                }
            }
        };
    },
    methods: {
        getList() {
            this.$nextTick(() => {
                const listStatus = this.$refs['list'].getStatus()
                if(listStatus) {
                    return
                }
                const dataForm = filterEmpty(this.queryForm)
                https({
                    url: this.url,
                    method: 'get',
                    data: dataForm,
                    options: {
                        message: false,
                        loading: true
                    }
                }).then( res => {
                    this.list.concat(res.data)
                    if(res.data.length > 0) {
                        this.$refs['list'].getList(res.data)
                    }
                    this.count = res.count
                    this.queryForm.PageIndex += 1 
                })
            })
        },
        isScrollAtBottom() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 获取当前滚动位置
            const windowHeight = window.innerHeight; // 浏览器视口的高度
            const documentHeight = document.documentElement.scrollHeight; // 文档的总高度
            return scrollTop + windowHeight >= documentHeight; // 当滚动位置加上窗口高度大于等于文档高度时，表明已滚动到底部
        },
        toSearch() {
            if(this.queryForm['ExhibitionID']) {
                this.$router.push({
                    path: '/mobile/search?ExhibitionID='+this.queryForm['ExhibitionID']
                })
            } else {
                this.$router.push({
                    path: '/mobile/search'
                })
            }
            
        },
        getParams(key) {
            const val = this.$route.query[key]
            if(val) {
                this.queryForm[key] = val
            }
        }
    }
}
</script>
<style scoped>
.listheader {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
.searchbar {
    height: 1.0667rem;
    background-color: #F5F1E7;
    margin-bottom: .2667rem;
}
.searchbar-input {
    width: 9.4667rem;
    height: .7467rem;
    line-height: .7467rem;
    font-size: .4rem;
    background: #FFFFFF;
    border-radius: .3733rem;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding-left: .4rem;
    color: #999999;
}
.searchbar-input img {
    position: absolute;
    width: .4667rem;
    top: 50%;
    transform: translateY(-50%);
    right: .4rem;
}
.listcontainer {
    padding-bottom: 2rem;
    background-color: #ffffff;
}
</style>