import axios from "axios";
import { Message, Loading } from "element-ui"

const https = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: window.baseURL,
  timeout: 10000
});

let loading = []
const myOptions = {};
const optionDefault = {
  message: true,
  loading: true
};
const extend = (o, n, override) => {
  let obj = JSON.parse(JSON.stringify(o))
  for (let p in n) {
    if (Object.prototype.hasOwnProperty.call(n, p) && (!Object.prototype.hasOwnProperty.call(obj, p) || override)) {
      obj[p] = n[p]
    }
  }
  return obj
};

https.interceptors.request.use(config => {
  let url = config.url
  if (config.method === 'get' && url.indexOf("?") !== -1) {
    url = url.slice(0, url.indexOf("?"))
  }
  if (config.options) {
    config.options = extend(optionDefault, config.options, true)
    myOptions[url] = config.options
  } else {
    myOptions[url] = optionDefault
  }
  if (myOptions[url].loading) {
    let a = Loading.service()
    loading.push(a)
  }
  delete config.options
  // 剔除值为null或undefined的键
  for (let key in config.data) {
    if (config.data[key] === null || config.data[key] === undefined || config.data[key] === '') {
      delete config.data[key];
    }
  }
  if (config.method === 'get' && config.data) {
    let query = '?'
    for (const key in config.data) {
      query += key + '=' + config.data[key] + '&'
    }
    query = query.slice(0, -1)
    config.url += query
    delete config.data
  }
  if (config.method === 'post') {
    const form = new FormData()
    for (const key in config.data) {
      form.append(key, config.data[key])
    }
    config.data = form
  }
  if (config.data && Object.keys(config.data).length === 0) {
    delete config.data
  }
  return config
}, error => {
  if (loading.length > 1) {
    loading.shift()
  } else if (loading.length === 1) {
    loading[0].close()
    loading.shift()
  }
  Message.error('操作失败')
  return Promise.reject(error)
})

https.interceptors.response.use(response => {
  if (loading.length > 1) {
    loading.shift()
  } else if (loading.length === 1) {
    loading[0].close()
    loading.shift()
  }
  const option = myOptions[response.config.url]
  if (response.status == 200) {
    let res = response.data
    if (res.code == 0) {
      if (option?.message) {
        Message.success(res.msg)
      }
      return res
    } else {
      Message.error(res.msg)
      return Promise.reject(res)
    }
  } else {
    Message.error('网络请求失败')
  }
}, error => {
  if (loading.length > 1) {
    loading.shift()
  } else if (loading.length === 1) {
    loading[0].close()
    loading.shift()
  }
  Message.error('网络请求失败')
  return Promise.reject(error)
})

export default https