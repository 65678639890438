<template>
    <div class="pubu">
        <div class="list" ref="left">
            <div class="block" v-for="(item,index) in left" :key="index" @click="toDetail(item)">
                <img :src="imgPath + item.ImgPath" alt="">
                <p class="block-title">{{ item.Title }}</p>
                <div class="block-info">
                    <p>时间：{{ item.StartTime }}-{{ item.EndTime }}</p>
                    <p>主办方：{{ item.Organizer }}</p>
                </div>
            </div>
        </div>
        <div class="list" ref="right" >
            <div class="block" v-for="(item,index) in right" :key="index" @click="toDetail(item)">
                <img :src="imgPath + item.ImgPath" alt="">
                <p class="block-title">{{ item.Title }}</p>
                <div class="block-info">
                    <p>时间：{{ item.StartTime }}-{{ item.EndTime }}</p>
                    <p>主办方：{{ item.Organizer }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isShow: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            datas: [],
            left: [],
            right: [],
            leftHeight: 0,
            rightHeight: 0,
            lock: false,
            lockCount: 0
        }
    },
    // mounted() {
    //         this.datas.forEach(item => {
    //             const that = this
    //             var img = new Image()
    //             img.src = item.picture
    //             img.onload = function() {
    //                 if(that.leftHeight <= that.rightHeight ) {
    //                     that.left.push(item)
    //                     that.leftHeight = that.leftHeight + Number(img.height)
    //                 }else {
    //                     that.rightHeight = that.rightHeight + Number(img.height)
    //                     that.right.push(item)
    //                 }
    //             }
    //         })
    // },
    methods: {
        getSort(data) {
            this.lock = true
            data.forEach(item => {
                const that = this
                var img = new Image()
                img.src = this.imgPath + item.ImgPath
                img.onload = function() {
                    if(that.leftHeight <= that.rightHeight ) {
                        that.left.push(item)
                        that.leftHeight = that.leftHeight + Number(img.height)
                    }else {
                        that.rightHeight = that.rightHeight + Number(img.height)
                        that.right.push(item)
                    }
                    that.lockCount += 1
                    if(that.lockCount === data.length) {
                        that.lockCount = 0
                        that.lock = false
                    }
                }
                img.onerror = function() {
                    if(that.leftHeight <= that.rightHeight ) {
                        that.left.push(item)
                    }else {
                        that.right.push(item)
                    }
                    that.lockCount += 1
                    if(that.lockCount === data.length) {
                        that.lockCount = 0
                        that.lock = false
                    }
                }
            })
        },
        initList() {
            this.left = []
            this.right = []
            this.datas = []
            this.leftHeight = 0
            this.rightHeight = 0
        }, 
        getList(data) {
            if(this.lock) {
                return
            }
            this.datas.concat(data)
            this.getSort(data)
        },
        getStatus() {
            return this.lock
        },
        toDetail(item) {
            this.$router.push({
                path: '/mobile/exhibitdetail',
                query: {
                    ExhibitionID: item.ExhibitionID,
                    isShow: this.isShow
                }
            })
        }
    }
}
</script>
<style scoped>
.pubu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: .2667rem;
    padding-right: .2667rem;
    background-color: #ffffff;
}
.list {
    width: 4.6rem;
    
    
}
.block {
    width: 4.6rem;
    padding-bottom: .8rem;
}
.block img {
    display: block;
    width: 100%;
}
.block-title {
    font-size: .3733rem;
    color: #333333;
    height: 1.0666rem;
    line-height: .5333rem;
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: .2rem;
    margin-bottom: .2rem;
}
.block-info {
    font-size: .2933rem;
    color: #999999;
    line-height: .3733rem;
}
.block-info p:first-child {
    margin-bottom: .0667rem;
}
</style>