<template>
  <div class="main-container">
    <img src="@/assets/img/12.png">
    <p>正在建设中，敬请期待！</p>
  </div>
</template>

<script>
export default {
  name: 'curation'
}
</script>

<style lang="scss" scoped>
.main-container{
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img{
    display: block;
  }
  p{
    font-size: 24px;
    line-height: 1;
    margin-top: 32px;
  }
}
</style>