<template>
  <div class="main-container">
    <div class="my-breadcrumb">
      <router-link to="/exhibition" class="breadcrumb-item active el-icon-">{{
        ExhibitionID !== 0 ? "展览" : "藏品"
      }}</router-link>
      <router-link
        v-if="ExhibitionID !== 0"
        :to="'/exhibition/collection?id=' + ExhibitionID"
        class="breadcrumb-item active el-icon-"
        >{{ detail.ExhibitionName }}</router-link
      >
      <span class="breadcrumb-item">{{ detail.name }}</span>
      <div class="btns">
        <el-button
          v-if="detail.PrevID !== 0"
          @click="toDetail(detail.PrevID)"
          class="my-button w-100 w-20 h-40 radius"
          >上一藏品</el-button
        >
        <el-button
          v-if="detail.NextID !== 0"
          @click="toDetail(detail.NextID)"
          class="my-button w-100 w-20 h-40 radius"
          >下一藏品</el-button
        >
        <el-button
          @click="$router.back()"
          class="my-button w-100 w-20 h-40 radius"
          >返回</el-button
        >
      </div>
    </div>
    <div class="detail">
      <div class="detail-left" id="my-previewImg1">
        <!-- <img class="detail-img" :key="id + 1" :src="detail.picture"> -->
        <el-image :src="detail.picture" :key="id" class="detail-img">
          <div slot="error" class="image-error">
            <div class="image-error-contianer">
              <i class="el-icon-picture-outline image-error-icon"></i>
              <p class="image-error-text">加载失败</p>
            </div>
          </div>
        </el-image>
        <div class="detail-look" @click="previewImg">
          <img src="@/assets/img/11.png" />查看大图
        </div>
      </div>
      <div class="detail-right">
        <p class="title">
          <span v-if="detail.ExhibitionName">{{ detail.ExhibitionName }}-</span
          >{{ detail.name }}
        </p>
        <div class="texts">
          <p class="text" v-if="detail.categoryOne">
            <span>藏品类别：</span>
            <span>{{ detail.categoryOne }}</span>
          </p>
          <p class="text status1" v-if="detail.courtOrgName">
            <span>收藏单位：</span>
            <span>{{ detail.courtOrgName }}</span>
          </p>
          <p class="text status1" v-if="detail.registerNo">
            <span>藏品登记号：</span>
            <span>{{ detail.registerNo }}</span>
          </p>
          <p class="text status1" v-if="detail.realAmount">
            <span>实际数量：</span>
            <span>{{ detail.realAmount }}</span>
          </p>
          <p class="text status1" v-if="detail.collectionDate">
            <span>入藏日期：</span>
            <span>{{ detail.collectionDate }}</span>
          </p>
          <p class="text status1" v-if="detail.source">
            <span>藏品来源：</span>
            <span>{{ detail.source }}</span>
          </p>
          <p class="text status1" v-if="detail.valueLevel">
            <span>价值级别：</span>
            <span>{{ detail.valueLevel }}</span>
          </p>
          <p class="text status1" v-if="detail.author">
            <span>作&emsp;&emsp;者：</span>
            <span>{{ detail.author }}</span>
          </p>
          <p class="text status1" v-if="detail.creationYear">
            <span>创作年代：</span>
            <span>{{ detail.creationYear }}</span>
          </p>
          <p class="text status1" v-if="detail.texture">
            <span>质&emsp;&emsp;地：</span>
            <span>{{ detail.texture }}</span>
          </p>
          <p class="text status1" v-if="detail.morphotype">
            <span>形态形制：</span>
            <span>{{ detail.morphotype }}</span>
          </p>
          <p class="text status1" v-if="detail.technology">
            <span>工艺技法：</span>
            <span>{{ detail.technology }}</span>
          </p>
          <p class="text status1"></p>
          <p class="text" v-if="detail.size">
            <span>尺&emsp;&emsp;寸：</span>
            <span>{{ detail.size }}</span>
          </p>
          <p class="text" v-if="detail.authorNotes">
            <span>作者简介：</span>
            <span>{{ detail.authorNotes }}</span>
          </p>
          <p class="text" v-if="detail.description">
            <span>作品简介：</span>
            <span>{{ detail.description }}</span>
          </p>
          <p class="text" v-if="detail.historical">
            <span>历史渊源：</span>
            <span>{{ detail.historical }}</span>
          </p>
          <p class="text" v-if="detail.academic">
            <span>学术成果：</span>
            <span>{{ detail.academic }}</span>
          </p>
          <p class="text" v-if="detail.titleNo > 0 && detail.titleParaphrase">
            <span>题&emsp;&emsp;名：</span>
            <span
              >{{ detail.titleNo }} （释文：{{ detail.titleParaphrase }}）</span
            >
          </p>
          <p
            class="text"
            v-if="detail.inscriptNo > 0 && detail.inscriptParaphrase"
          >
            <span>款&emsp;&emsp;识：</span>
            <span
              >{{ detail.inscriptNo }} （释文：{{
                detail.inscriptParaphrase
              }}）</span
            >
          </p>
          <p
            class="text"
            v-if="detail.prefaceNo > 0 && detail.prefaceParaphrase"
          >
            <span>题&emsp;&emsp;跋：</span>
            <span
              >{{ detail.prefaceNo }} （释文：{{
                detail.prefaceParaphrase
              }}）</span
            >
          </p>
          <p
            class="text"
            v-if="detail.epigraphNo > 0 && detail.epigraphParaphrase"
          >
            <span>铭&emsp;&emsp;文：</span>
            <span
              >{{ detail.epigraphNo }} （释文：{{
                detail.epigraphParaphrase
              }}）</span
            >
          </p>
          <p class="text" v-if="detail.labelNo > 0 && detail.labelParaphrase">
            <span>题&emsp;&emsp;签：</span>
            <span
              >{{ detail.labelNo }} （释文：{{ detail.labelParaphrase }}）</span
            >
          </p>
          <!-- <p class="text">
            <span>题跋释文：</span>
            <span>{{ detail.prefaceParaphrase }}</span>
          </p> -->
          <p class="text" v-if="detail.stampNo > 0 && detail.stampParaphrase">
            <span>印&emsp;&emsp;鉴：</span>
            <span
              >{{ detail.stampNo }} （释文：{{ detail.stampParaphrase }}）</span
            >
          </p>
          <!-- <p class="text">
            <span>印鉴释文：</span>
            <span>{{ detail.stampParaphrase }}</span>
          </p> -->
        </div>
      </div>
    </div>
    <div class="style1" :class="{ active: isShow }">
      <div class="style1-container">
        <!-- <img
          class="detail-img"
          :key="id + 1"
          id="previewImg"
          :src="currentPic"
        /> -->
        <div id="previewImg">
          <img :src="item" v-for="(item, index) in picture" :key="index" />
        </div>
        <img src="@/assets/img/13.png" class="closeicon" @click="close">
        <!-- <el-button
          v-if="isShow"
          @click="close"
          class="close-previewImg my-button w-100 w-20 h-40 radius"
          >关闭</el-button
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs/dist/viewer";
export default {
  name: "detail",
  data() {
    return {
      previewImgData: null,
      isShow: false,
      id: 0,
      ExhibitionID: 0,
      detail: {},
      picture: [],
      currentPic: "",
      currentIndex: 0,
      interval: null,
    };
  },
  mounted() {
    this.id = parseInt(this.$route.query.id);
    this.ExhibitionID = parseInt(this.$route.query.ExhibitionID) || 0;
    this.getDetail();
  },
  methods: {
    getDetail() {
      let data = {
        CollectibleID: this.id,
      };
      if (this.ExhibitionID !== 0) {
        data.ExhibitionID = this.ExhibitionID;
      }
      this.https({
        url: "/api/webapi/Collectibles_GetDetail",
        method: "get",
        data: data,
        options: {
          message: false,
        },
      }).then(({ data }) => {
        this.detail = data;
        this.picture = data.pictures.split(",");
        this.currentPic = this.picture[0];
        if (this.previewImgData) {
          this.previewImgData.destroy();
        }
        this.$nextTick(() => {
          this.previewImgData = new Viewer(
            this.$el.querySelector("#previewImg"),
            {
              button: false,
              title: false,
              toolbar: false,
              navbar: false,
              backdrop: false,
              className: "my-previewImg",
              inline: true,
              minWidth: 1400,
              maxWidth: 755,
            }
          );

          //按钮位置
          // var closeicon = document.getElementsByClassName('closeicon')[0]
          // var viewerCanvas = document.getElementsByClassName("viewer-canvas")[0];
          // var img = viewerCanvas.getElementsByTagName("img")[0];
          // console.dir(img)
          
        });
      });
    },
    close() {
      this.isShow = false;
      this.previewImgData.reset();
    },
    previewImg() {
      // this.previewImgData.show()
      const that = this;
      this.isShow = true;

      if (this.picture.length > 1) {
        that.touchPause();
        this.interval = setInterval(function () {
          if (that.currentIndex == that.picture.length - 1) {
            that.previewImgData.update();
            that.currentIndex = 0;
          } else {
            that.currentIndex++;
            that.previewImgData.next();
          }
          that.touchPause();
        }, 3000); // 每3秒切换一次
      }

      // this.$nextTick(() => {
      //   var viewerCanvas = document.getElementsByClassName("viewer-canvas")[0];
      //   var img = viewerCanvas.getElementsByTagName("img")[0];

      //   const options = {
      //       attributes: true,
      //       childList: true,
      //   }
      //   var observer = new MutationObserver((mutationsList) => {
      //       for (let mutation of mutationsList) {
      //           console.log(mutation.target.offsetTop)
      //       }
      //   })
      //   observer.observe(img,options)
      // });
    },
    touchPause() {
      const that = this;
      this.$nextTick(() => {
        let viewerMove = document.querySelector(".viewer-canvas");
        let viewMoveImg = viewerMove.getElementsByTagName("img")[0];
        viewMoveImg.addEventListener("mouseenter", function () {
          clearInterval(that.interval);
          that.interval = null;
        });
        viewMoveImg.addEventListener("mouseleave", function () {
          clearInterval(that.interval);
          that.interval = null;
          that.interval = setInterval(function () {
            if (that.currentIndex == that.picture.length - 1) {
              that.previewImgData.update();
              that.currentIndex = 0;
            } else {
              that.currentIndex++;
              that.previewImgData.next();
            }
            that.touchPause();
          }, 3000); // 每3秒切换一次
        });
      });
    },
    toDetail(id) {
      this.$router.replace({
        query: {
          id: id,
        },
      });
      this.id = id;
      this.ExhibitionID = 0;
      this.getDetail();
    },
  },
  watch: {
    isShow(newVal) {
      if (newVal == false) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-breadcrumb {
  position: relative;
  .btns {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.detail {
  background: #ffffff;
  padding: 58px 50px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 66px;
  .detail-left {
    width: 450px;
    flex-shrink: 0;
    text-align: right;
    margin-right: 49px;
  }
  .detail-img {
    width: 100%;
    height: 450px;
    display: block;
    pointer-events: none;
  }
  .detail-look {
    margin-top: 17px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    img {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .detail-right {
    flex-grow: 1;
  }
  .title {
    font-size: 24px;
    line-height: 34px;
    margin-top: -5px;
    padding-bottom: 22px;
    border-bottom: 1px solid #cbcbcb;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 16px;
      height: 3px;
      background: #333333;
      border-radius: 1px;
      bottom: -2px;
      left: 0;
    }
  }
  .texts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 10px;
  }
  .text {
    width: 100%;
    font-size: 14px;
    line-height: 30px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 11px;
    &.status1 {
      width: 33.33%;
    }
    &.img {
      margin-bottom: 0;
      span:nth-child(2) {
        margin-top: -10px;
        display: flex;
        flex-wrap: wrap;
        img {
          display: block;
          width: 140px;
          height: 140px;
          margin-bottom: 10px;
          & + img {
            margin-left: 20px;
          }
        }
      }
    }
    span:nth-child(1) {
      flex-shrink: 0;
      color: #999999;
    }
    span:nth-child(2) {
      flex-grow: 1;
      color: #333333;
    }
  }
}
.style1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.8);
  z-index: -1;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    z-index: 2000;
    opacity: 1;
  }
  .style1-container {
    position: absolute;
    width: 1400px;
    height: 755px;
  }
}
#previewImg {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.close-previewImg {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 140%);
  bottom: 0;
}
.closeicon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.closeicon {
  right: -30px;
}
// .viewer-canvas::after {
//   content: "";
//   width: 30px;
//   height: 30px;
//   position: absolute;
//   top: 0;
//   right: 0;
//   cursor: pointer;
//   background: url("../../assets/img/13.png") no-repeat;
//   background-size: 100% 100%;
// }
</style>