<template>
  <div class="container">
    <div class="header">
      <p class="title">美术馆藏品公众服务展示系统</p>
      <div class="nav">
        <router-link
          class="nav-item"
          v-for="item,index in navList"
          :to="item.url"
          :key="index">{{ item.title }}</router-link>
      </div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
    <p class="footer"><a :href="url1">{{ info.NSRecord }}</a>&emsp;<a href="http://beian.miit.gov.cn/">{{ info.IPCRecord }}</a>&emsp;{{ info.CopyRight }}</p>
  </div>
</template>

<script>
export default {
  name: 'container',
  data () {
    return {
      navList: [
        {
          title: '首页',
          url: '/index'
        },{
          title: '藏品',
          url: '/collection'
        },{
          title: '展览',
          url: '/exhibition'
        },{
          title: '策展',
          url: '/curation'
        }
      ],
      info: {},
      url1: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode='
    }
  },
  mounted () {
    this.https({
      url: '/api/webapi/GetSiteInfo',
      method: 'get',
      options: {
        message: false,
        loading: true
      }
    }).then(({ data }) => {
      this.info = data
      let num = data.NSRecord.match(/[0-9]+/)
      this.url1 += num[0]
      const header = document.getElementsByTagName('head')[0]
      const title = header.querySelector("title")
      title.innerHTML = data.Title
      const meta1 = document.createElement('meta')
      const meta2 = document.createElement('meta')
      meta1.setAttribute('name', 'keywords')
      meta1.setAttribute('content', data.Keywords)
      meta2.setAttribute('name', 'description')
      meta2.setAttribute('content', data.Description)
      header.appendChild(meta1)
      header.appendChild(meta2)
    })
  }
}
</script>

<style lang="scss" scoped>
$--header-height: 80px;
.container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.header{
  height: $--header-height;
  background: #F5F1E7;
  flex-shrink: 0;
  position: relative;
  .title{
    position: absolute;
    font-size: 28px;
    font-weight: bold;
    line-height: $--header-height;
    left: 30px;
  }
  .nav{
    height: $--header-height;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-item{
    font-size: 18px;
    margin: 0 30px;
    transition: color .1s;
    &.router-link-active,&:hover{
      color: #118373;
    }
  }
}
.main{
  flex-grow: 1;
  background-color: #E3D5C5;
  // overflow: auto;
  &::-webkit-scrollbar{
    height: 0;
  }
  ::v-deep .el-scrollbar__wrap{
    &::-webkit-scrollbar{
      height: 0;
    }
  }
  .main-container{
    width: 1400px;
    margin: 0 auto;
    position: relative;
    min-height: 100%;
  }
}
.footer{
  flex-shrink: 0;
  text-align: center;
  line-height: 40px;
  background: #F5F1E7;
  font-size: 12px;
}
</style>