<template>
    <header>
        <img src="@/assets/img/z1.png" class="icon" @click="back" v-if="icon">
        {{ title }}
    </header>
</template>
<script>
export default{
    props: {
        icon: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '美术馆藏品公众服务展示系统'
        }
    },
    data() {
        return {}
    },
    methods: {
        back() {
            this.$router.go(-1)
        }
    }
}
</script>
<style scoped>
header {
    max-width: 750px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    background: #F5F1E7;
    position: relative;
}
.icon {
    width: .4267rem;
    left: .2667rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
</style>